import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SalarySlipListTemplate from 'components/Templates/MyPay/SalarySlips/List';
import { fetchSalarySlipList } from 'store';
import { useThunk } from 'hooks/useThunk';
import { useNavigate } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';
import { Typography } from '@mui/material';
import useToast from 'components/Provider/useToast';
import dayjs from 'dayjs';
import { validateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';
import useColumns from './useColumns';

const SalarySlipPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [salarySlipDataColumn] = useColumns();

  const [getSalarySlipList, isLoadingSalarySlipList, loadingErrorSalarySlipListError] = useThunk(fetchSalarySlipList);

  useEffect(() => {
    getSalarySlipList();
  }, [getSalarySlipList]);

  const { salarySlipList } = useSelector((state) => state.salarySlip);
  const salarySlipData = salarySlipList?.data?.salarySlipInstanceList;

  if (loadingErrorSalarySlipListError !== null) {
    return (
      <Typography variant="h6">
        {loadingErrorSalarySlipListError?.message || 'Error while fetching salary slips.'}
      </Typography>
    );
  }

  const fetchSalarySlipDetailsFunc = (row) => {
    if (row?.ssid) {
      navigate(`/home/mypay/salary-slips-details/${row.ssid}`, {
        state: { consolidatedSalarySlipsFlag: row?.consolidatedSalarySlipsFlag, salarySlipId: row?.salarySlipId },
      });
    } else {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: 'Something went wrong, try again',
      });
    }
  };

  const onRowClick = (row) => {
    fetchSalarySlipDetailsFunc(row);
  };

  const checkAndUpdateValue = (value1, value2, dateFormat) => {
    if (value1) {
      return dayjs(value1).format(dateFormat);
    }
    if (value2) {
      return dayjs(value1).format(dateFormat);
    }
    return '-';
  };

  const salarySlipDataRow =
    isArray(salarySlipData) && !isEmpty(salarySlipData)
      ? salarySlipData.map((val, index) => ({
          ssid: validateElseHyphen(val?.id || val?.payPeriod?.id),
          slNo: index + 1,
          periodStartDate: checkAndUpdateValue(val?.periodStartDate, val?.payPeriod?.payPeriodStartDate, 'DD MMM YYYY'),
          periodEndDate: checkAndUpdateValue(val?.periodEndDate, val?.payPeriod?.payPeriodEndDate, 'DD MMM YYYY'),
          payPeriod: `${checkAndUpdateValue(
            val?.periodStartDate,
            val?.payPeriod?.payPeriodStartDate,
            'DD MMM YYYY',
          )} - ${checkAndUpdateValue(val?.periodEndDate, val?.payPeriod?.payPeriodEndDate, 'DD MMM YYYY')}`,
          grossEarnings: `₹ ${validateNumberElseHyphen(val?.netFinalEarnings)}`,
          deductions: `₹ ${validateNumberElseHyphen(val?.netFinalDeductions)}`,
          netEarnings: `₹ ${validateNumberElseHyphen(val?.netFinalSalary)}`,
          consolidatedSalarySlipsFlag: !!salarySlipList?.data?.consolidatedSalarySlips,
          salarySlipId: val?.salarySlipId,
        }))
      : [];

  return (
    <SalarySlipListTemplate
      salarySlipDataColumn={salarySlipDataColumn}
      salarySlipDataRow={salarySlipDataRow}
      onRowClick={onRowClick}
      loading={isLoadingSalarySlipList}
    />
  );
};

export default SalarySlipPage;
