import GroupIcon from '@mui/icons-material/Groups';
import SpeedIcon from '@mui/icons-material/Speed';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const myTeam = {
  id: 'myteam',
  title: 'My Team',
  icon: GroupIcon,
  type: 'group',
  url: '',
  children: [
    {
      id: 'teamDashboard',
      title: 'Dashboard',
      icon: SpeedIcon,
      type: 'item',
      url: '/myteam/dashboard',
      permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM'],
    },
    {
      id: 'attendance',
      title: 'Attendance',
      icon: EventAvailableIcon,
      type: 'collapse',
      permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM'],
      children: [
        {
          id: 'attendanceDashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/myteam/attendance/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM'],
        },
      ],
    },
    // {
    //   id: 'employee',
    //   title: 'Employee',
    //   icon: PersonIcon,
    //   type: 'collapse',
    //   permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_EMPLOYEE_EXITS'],
    //   children: [],
    // },
    {
      id: 'myteam_expenses',
      title: 'Expenses',
      icon: AccountBalanceWalletIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM',
        'ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_ORG_EXPENSE_REPORTS',
        'ROLE_BASE_PERMISSION_EXPENSE_INDEX_MY_EXPENSE_REPORTS_LIST',
      ],
      children: [
        {
          id: 'team_expenses_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/myteam/expenses/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM'],
        },
        {
          id: 'teamExpenses',
          title: 'Team Expenses',
          icon: ArticleIcon,
          type: 'item',
          url: '/myteam/expenses',
          permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_ORG_EXPENSE_REPORTS'],
        },
        {
          id: 'reportsWithPendingMyAction',
          title: 'Reports Pending my Action',
          icon: FormatListBulletedIcon,
          type: 'item',
          url: '/myteam/expenses/actionable-reports',
          permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_MY_EXPENSE_REPORTS_LIST'],
        },
      ],
    },
    {
      id: 'myteam_leaves',
      title: 'Leaves',
      icon: BeachAccessIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM',
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_TEAM_LEAVE_REQUESTS',
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_REQUESTS_PENDING_MY_ACTION',
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_TEAM_LEAVE_ACCOUNTS',
      ],
      children: [
        {
          id: 'team_leaves_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/myteam/leaves/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM'],
        },
        {
          id: 'teamLeaveRequests',
          title: 'Team Leave Requests',
          icon: OfflineBoltIcon,
          type: 'item',
          url: '/myteam/leaves/team-leave-requests',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_TEAM_LEAVE_REQUESTS'],
        },
        {
          id: 'teamLeaveAccounts',
          title: 'Team Leave Accounts',
          icon: ArticleIcon,
          type: 'item',
          url: '/myteam/leaves/team-leave-accounts',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_TEAM_LEAVE_ACCOUNTS'],
        },
        {
          id: 'requestPendingMyAction',
          title: 'Requests pending my action',
          icon: FormatListBulletedIcon,
          type: 'item',
          url: '/myteam/leaves/requests-pending-action',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_REQUESTS_PENDING_MY_ACTION'],
        },
      ],
    },
    {
      id: 'teamDocuments',
      title: 'Team Documents',
      icon: InsertDriveFileIcon,
      type: 'item',
      url: '/myteam/documents',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_MY_ORG_DOCUMENTS'],
    },
    {
      id: 'separations',
      title: 'Separations',
      icon: LinkOffIcon,
      type: 'item',
      url: '/myteam/separations',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_EMPLOYEE_EXITS'],
    },
    {
      id: 'team_lifecycle_records',
      title: 'Lifecycle Records',
      icon: PersonIcon,
      type: 'collapse',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL'],
      children: [
        {
          id: 'team_lifecycle-records_pending_approval',
          title: 'Life Cycle Case Records Pending Approval',
          icon: LocalPoliceIcon,
          type: 'item',
          url: '/myteam/life-cycle/life-cycle-records-pending-approval',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL'],
        },
      ],
    },
  ],
};

export default myTeam;
