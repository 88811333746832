import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import { Box, Grid, Divider, useTheme, Avatar } from '@mui/material';
import Button from 'components/Atoms/Button';
import { Plus as PlusIcon } from 'assets/icons/plus';
import RemoveIcon from '@mui/icons-material/Remove';
import DatePicker from 'components/Atoms/Datepicker';
import Checkbox from 'components/Atoms/Checkbox';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import { FieldArray, getIn } from 'formik';

const OtherIncomeForm = ({ propertyTypeOption, formik }) => {
  const theme = useTheme();
  const propertyTypeName = useCallback(
    (type) => propertyTypeOption.find((o) => o.id === type)?.name,
    [propertyTypeOption],
  );

  const defObj = {
    propertyType: '',
    propertyName: '',
    address: '',
    constructionCompletedOn: '',
    loanAmount: '',
    loanBorrowedOn: '',
    lenderName: '',
    lenderPAN: '',
    jointlyOwnedProperty: '',
    coBorrower1Name: '',
    coBorrower1Pan: '',
    coBorrower1Relationship: '',
    coBorrower2Name: '',
    coBorrower2Pan: '',
    coBorrower2Relationship: '',
    coBorrower3Name: '',
    coBorrower3Pan: '',
    coBorrower3Relationship: '',
    emiInterest1Year: '',
    emiInterest2Year: '',
    percentageOfInterestRepaid: '',
    interest: '',
    lettableValue: '',
    taxPaidToLocalAuthorities: '',
    standardDeductionOnLettableValue: '',
    interestPaidOnHousingLoan: '',
    netIncomeFromHouse: '',
    declarationProperty: '',
  };

  return (
    <Box>
      <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
      <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
        <Grid item xs={12} sm={4} md={3}>
          <Typographys variant="body1" color="neutral.800">
            Income from other sources
          </Typographys>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <InputField
            name="incomeOtherSources"
            id="incomeOtherSources"
            label="Amount"
            value={formik.values?.incomeOtherSources}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
        </Grid>
      </Grid>
      <FieldArray name="property">
        {({ push, remove }) => (
          <>
            <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
            <Box sx={tdsStyle.headingWithActionBtn} mb={4}>
              <Typographys variant="subtitle1">House/Property Details</Typographys>
              <Button
                size="large"
                startIcon={<PlusIcon />}
                onClick={() => {
                  push(defObj);
                }}
              >
                Add Property
              </Button>
            </Box>
            {formik?.values?.property?.map((p, index) => (
              <>
                <Box sx={tdsStyle.headingWithActionBtn}>
                  <Typographys variant="body2" color="neutral.600">
                    Property {index + 1}
                  </Typographys>
                  <Button
                    size="large"
                    variant="outlined"
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Remove
                  </Button>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={2} mt={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Dropdown
                      label="Property Type*"
                      options={propertyTypeOption}
                      value={p?.propertyType}
                      name={`property[${index}].propertyType`}
                      id={`property[${index}].propertyType`}
                      onChange={formik.handleChange}
                      error={Boolean(
                        getIn(formik.touched, `property[${index}].propertyType`) &&
                          getIn(formik.errors, `property[${index}].propertyType`),
                      )}
                      helperText={
                        getIn(formik.touched, `property[${index}].propertyType`) &&
                        getIn(formik.errors, `property[${index}].propertyType`)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].propertyName`}
                      id={`property[${index}].propertyName`}
                      label="Property Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.propertyName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].address`}
                      id={`property[${index}].address`}
                      label="Address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.address}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      name={`property[${index}].constructionCompletedOn`}
                      id={`property[${index}].constructionCompletedOn`}
                      label="Construction Completed on"
                      onChange={(value) => {
                        formik.setFieldValue(`property[${index}].constructionCompletedOn`, value);
                      }}
                      onBlur={formik.handleBlur}
                      value={p?.constructionCompletedOn}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].loanAmount`}
                      id={`property[${index}].loanAmount`}
                      label="Loan Amount*"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.loanAmount}
                      error={Boolean(
                        getIn(formik.touched, `property[${index}].loanAmount`) &&
                          getIn(formik.errors, `property[${index}].loanAmount`),
                      )}
                      helperText={
                        getIn(formik.touched, `property[${index}].loanAmount`) &&
                        getIn(formik.errors, `property[${index}].loanAmount`)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      name={`property[${index}].loanBorrowedOn`}
                      id={`property[${index}].loanBorrowedOn`}
                      label="Loan Borrowed On"
                      onChange={(value) => {
                        formik.setFieldValue(`property[${index}].loanBorrowedOn`, value);
                      }}
                      onBlur={formik.handleBlur}
                      value={p?.loanBorrowedOn}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].lenderName`}
                      id={`property[${index}].lenderName`}
                      label="Lender Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.lenderName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].lenderPAN`}
                      id={`property[${index}].lenderPAN`}
                      label="Lender PAN"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.lenderPAN}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2.5, mb: 3.5, borderColor: theme.palette.other[200] }} />
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={p?.isJointlyOwnedProperty}
                  checked={p?.isJointlyOwnedProperty?.includes('on')}
                  color="secondary"
                  name={`property[${index}].isJointlyOwnedProperty`}
                  label="Is Jointly Owned Property?"
                />
                {p?.isJointlyOwnedProperty?.includes('on') && (
                  <Grid container rowSpacing={2} columnSpacing={2} mt={0}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower1Name`}
                        id={`property[${index}].coBorrower1Name`}
                        label="Co Borrower 1 Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower1Name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower1Pan`}
                        id={`property[${index}].coBorrower1Pan`}
                        label="Co Borrower 1 PAN"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower1Pan}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower1Relationship`}
                        id={`property[${index}].coBorrower1Relationship`}
                        label="Co Borrower 1 Relationship"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower1Relationship}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower2Name`}
                        id={`property[${index}].coBorrower2Name`}
                        label="Co Borrower 2 Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower2Name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower2Pan`}
                        id={`property[${index}].coBorrower2Pan`}
                        label="Co Borrower 2 PAN"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower2Pan}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower2Relationship`}
                        id={`property[${index}].coBorrower2Relationship`}
                        label="Co Borrower 2 Relationship"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower2Relationship}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower3Name`}
                        id={`property[${index}].coBorrower3Name`}
                        label="Co Borrower 3 Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower3Name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower3Pan`}
                        id={`property[${index}].coBorrower3Pan`}
                        label="Co Borrower 3 PAN"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower3Pan}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputField
                        name={`property[${index}].coBorrower3Relationship`}
                        id={`property[${index}].coBorrower3Relationship`}
                        label="Co Borrower 3 Relationship"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={p?.coBorrower3Relationship}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                <Divider sx={{ mt: 3.5, borderColor: theme.palette.other[200] }} />
                <Grid container rowSpacing={2} columnSpacing={2} mt={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].emiInterest1Year`}
                      id={`property[${index}].emiInterest1Year`}
                      type="number"
                      label="Pre-EMI Interest - Year 1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.emiInterest1Year}
                      fullWidth
                    />
                    <Typographys variant="caption" color="neutral.500">
                      (Exemption limited to 20% only)
                    </Typographys>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField
                      name={`property[${index}].emiInterest2Year`}
                      id={`property[${index}].emiInterest2Year`}
                      type="number"
                      label="Pre-EMI Interest - Year 2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={p?.emiInterest2Year}
                      fullWidth
                    />
                    <Typographys variant="caption" color="neutral.500">
                      (Exemption limited to 20% only)
                    </Typographys>
                  </Grid>
                  {propertyTypeName(p.propertyType) === 'Self-Occupied Property' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].percentageOfInterestRepaid`}
                          id={`property[${index}].percentageOfInterestRepaid`}
                          label="Percentage of Interest on Loan Repaid by Self"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.percentageOfInterestRepaid}
                          fullWidth
                        />
                        <Typographys variant="caption" color="neutral.500">
                          (For Self-Occupied Property Only)
                        </Typographys>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].interest`}
                          id={`property[${index}].interest`}
                          type="number"
                          label="Interest"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.interest}
                          fullWidth
                        />
                        <Typographys variant="caption" color="neutral.500">
                          (On self occupied property only, per section 24)
                        </Typographys>
                      </Grid>
                    </>
                  )}
                </Grid>

                {propertyTypeName(p.propertyType) !== 'Self-Occupied Property' && (
                  <>
                    <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
                    <Grid container rowSpacing={2} columnSpacing={2} mt={0}>
                      <Grid item xs={12} sm={6} md={5}>
                        <Box sx={tdsStyle.listIcon}>
                          <Avatar>A</Avatar>
                          <Typographys variant="body2" color="neutral.600">
                            Lettable Value *
                          </Typographys>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].lettableValue`}
                          id={`property[${index}].lettableValue`}
                          type="number"
                          label=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.lettableValue}
                          error={Boolean(
                            getIn(formik.touched, `property[${index}].lettableValue`) &&
                              getIn(formik.errors, `property[${index}].lettableValue`),
                          )}
                          helperText={
                            getIn(formik.touched, `property[${index}].lettableValue`) &&
                            getIn(formik.errors, `property[${index}].lettableValue`)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Box sx={tdsStyle.listIcon}>
                          <Avatar>B</Avatar>
                          <Typographys variant="body2" color="neutral.600">
                            Tax Paid To Local Authorities *
                          </Typographys>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].taxPaidToLocalAuthorities`}
                          id={`property[${index}].taxPaidToLocalAuthorities`}
                          type="number"
                          label=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.taxPaidToLocalAuthorities}
                          error={Boolean(
                            getIn(formik.touched, `property[${index}].taxPaidToLocalAuthorities`) &&
                              getIn(formik.errors, `property[${index}].taxPaidToLocalAuthorities`),
                          )}
                          helperText={
                            getIn(formik.touched, `property[${index}].taxPaidToLocalAuthorities`) &&
                            getIn(formik.errors, `property[${index}].taxPaidToLocalAuthorities`)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Box sx={tdsStyle.listIcon}>
                          <Avatar>C</Avatar>
                          <Typographys variant="body2" color="neutral.600">
                            Standard Deduction On Lettable Value (30% of (A-B))
                          </Typographys>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].standardDeductionOnLettableValue`}
                          id={`property[${index}].standardDeductionOnLettableValue`}
                          type="number"
                          label=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.standardDeductionOnLettableValue}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Box sx={tdsStyle.listIcon}>
                          <Avatar>D</Avatar>
                          <Typographys variant="body2" color="neutral.600">
                            Interest Paid On Housing Loan (max 2,00,000 pa)*
                          </Typographys>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].interestPaidOnHousingLoan`}
                          id={`property[${index}].interestPaidOnHousingLoan`}
                          type="number"
                          label=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.interestPaidOnHousingLoan}
                          error={Boolean(
                            getIn(formik.touched, `property[${index}].interestPaidOnHousingLoan`) &&
                              getIn(formik.errors, `property[${index}].interestPaidOnHousingLoan`),
                          )}
                          helperText={
                            getIn(formik.touched, `property[${index}].interestPaidOnHousingLoan`) &&
                            getIn(formik.errors, `property[${index}].interestPaidOnHousingLoan`)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Box sx={tdsStyle.listIcon}>
                          <Avatar>E</Avatar>
                          <Typographys variant="body2" color="neutral.600">
                            Net Income From House Property (A-B-C-D) (loss limited to 2,00,00 from FY 17-18)
                          </Typographys>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          name={`property[${index}].netIncomeFromHouse`}
                          id={`property[${index}].netIncomeFromHouse`}
                          type="number"
                          label=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={p?.netIncomeFromHouse}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                {propertyTypeName(p.propertyType) === 'Self-Occupied Property' && (
                  <>
                    <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
                    <Typographys variant="subtitle1" color="neutral.800">
                      Declaration (for claiming 100% interest on joint property)
                    </Typographys>
                    <Box sx={tdsStyle.checkboxWithLabel}>
                      <Checkbox
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        key={p?.declarationProperty}
                        checked={p?.declarationProperty?.includes('on')}
                        color="secondary"
                        name={`property[${index}].declarationProperty`}
                        label=""
                      />
                      <Typographys variant="body2" color="neutral.800">
                        This is to inform the concerned authorities that the property mentioned is a joint property:
                        however the other owner(s) of the property is(are) not enjoying any tax benefits on the same and
                        has granted me the permission to avail tax benefits on the same.
                      </Typographys>
                    </Box>
                  </>
                )}
              </>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  );
};
OtherIncomeForm.defaultProps = {
  formik: {
    values: {},
  },
  propertyTypeOption: [],
};
OtherIncomeForm.propTypes = {
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  propertyTypeOption: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
};

export default OtherIncomeForm;
