/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import TDSTemplate from 'components/Templates/MyPay/TDS/View';
import { useThunk } from 'hooks/useThunk';
import { fetchViewTDS, fetchModifyViewTDS } from 'store';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { formDataApi } from 'api/api';
import useToast from 'components/Provider/useToast';
import dayjs from 'dayjs';
import { validateDateElseHyphen } from 'utils/commonUtils';
import useDownloadFile from 'hooks/useDownloadFile';
import Dialog from 'components/Molecules/Dialog';
import { isEmpty, isObject, get } from 'lodash';
import { hasPermission } from 'utils/permissions';
import {
  EMPLOYEE_PROOF_SUBMISSION_STATUS,
  NEW_TAX_REGIME,
  NEW_TAX_REGIME_2020,
  OPEN_FOR_DECLARATION,
  PROOF_SUBMISSION_STATUS,
} from 'utils/SystemConfigConstants';
import { getHouseRentData, getInvestmentDeclarationData, configureUpdateFormData } from './getConfiguredData';

const ViewTDSPage = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();
  const [houseRentData, setHouseRentData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [submitProofDialog, setSubmitProofDialog] = useState(false);
  const [submitSuccessDialog, setSubmitSuccessDialog] = useState(false);
  const [investmentDeclarations, setInvestmentDeclaration] = useState([]);
  const [formData, setFormData] = useState('');
  const [selectedRegime, setSelectedRegime] = useState('');
  const { loading, tdsDetails, error } = useSelector((state) => state?.compareTDS?.viewTDSData);
  const addToast = useToast();
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const authorities = useSelector((state) => state.session.user.authorities);
  const PAYROLL_PROOF_SUBMISSION_DRAFT = 'Draft Proofs';
  const PAYROLL_OPEN_FOR_PROOF_SUBMISSION = 'Open for Proof Submission';
  const employeeDeductionDeclarationInstance = tdsDetails?.data?.employeeDeductionDeclarationInstance;
  const proofSubmissionStatus = location?.state;

  const isNewTaxRegime = employeeDeductionDeclarationInstance?.taxRegime?.includes(
    NEW_TAX_REGIME || NEW_TAX_REGIME_2020,
  );
  const proofSubmissionStatusInDraft =
    employeeDeductionDeclarationInstance?.proofSubmissionStatus === EMPLOYEE_PROOF_SUBMISSION_STATUS || '';

  const isPayrollOpenForDeclaration =
    tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationStatus === OPEN_FOR_DECLARATION;

  const userHasComparePermisson =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_ASSESS_MY_REGIMEWISE_TDS']) &&
    isPayrollOpenForDeclaration;

  const showUploadInvestmentProof =
    tdsDetails?.data?.employeeDeductionDeclarationInstance.declarationProofStatus === PAYROLL_OPEN_FOR_PROOF_SUBMISSION;
  const userHasUploadProofPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS']) &&
    showUploadInvestmentProof;

  const showProofSubmission =
    tdsDetails?.data?.employeeDeductionDeclarationInstance.proofSubmissionStatus === PAYROLL_PROOF_SUBMISSION_DRAFT;
  const userHasProofSubmitPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_SUBMIT_MY_PROOFS']) && showProofSubmission;

  const handleEdit = (event) => {
    event.preventDefault();
    navigate('/home/mypay/tds-declaration/edit');
  };

  const handleSubmission = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('eid', tdsDetails?.data?.employeeDeductionDeclarationInstance?.employee?.id);
    bodyFormData.append('did', tdsDetails?.data?.employeeDeductionDeclarationInstance?.id);
    try {
      const response = await formDataApi.post(`restPayIndex/submitMyInvestmentProofs`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Investment proofs submitted successfully',
        });
      }
      setSubmitSuccessDialog(true);
    } catch (submmitError) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: submmitError?.message || 'Failed to submit investment proof',
      });
    }
  };

  const compareTdsMenu = userHasComparePermisson && {
    id: 1,
    label: 'Compare TDS regime wise',
    onClick: () => {
      navigate('/home/mypay/compare-tds');
    },
  };

  const uploadInvestmentProofButton = userHasUploadProofPermission && {
    id: 2,
    label: 'Upload Investment Proofs',
    onClick: () => {
      navigate('/home/mypay/investment-proofs');
    },
    permissions: ['PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS'],
  };

  const SubmitInvestmentProofs = !!userHasProofSubmitPermission &&
    proofSubmissionStatusInDraft &&
    !isNewTaxRegime && {
      id: 3,
      label: 'Submit Investment Proofs',
      onClick: () => setSubmitProofDialog(true),
    };

  const menuLists = [compareTdsMenu, uploadInvestmentProofButton, SubmitInvestmentProofs];

  const regimeOptions = [
    { id: 'Old Tax Regime', name: 'Old Tax Regime' },
    { id: 'New Tax Regime', name: 'New Tax Regime' },
  ];

  const propertyTypeOption = [
    { id: 'Self-Occupied Property', name: 'Self-Occupied Property' },
    { id: 'LetOut Property', name: 'LetOut Property' },
  ];

  const [getTdsDetails, isLoadingTdsDetails, loadingTdsDetailsError] = useThunk(fetchViewTDS);
  const [getModifyTdsDetails, isLoadingModifyTdsDetails, loadingModifyTdsDetailsError] = useThunk(fetchModifyViewTDS);

  useEffect(() => {
    const query = new URLSearchParams({});
    if (isEditMode) {
      getModifyTdsDetails(query);
    } else {
      getTdsDetails(query);
    }
  }, [isEditMode, getTdsDetails, addToast]);
  useEffect(() => {
    if (
      tdsDetails?.data &&
      tdsDetails?.data?.uniqueMnNumMonthNameMap &&
      tdsDetails?.data?.employeeDeductionDeclarationInstance
    ) {
      const houseData = getHouseRentData(tdsDetails);
      setHouseRentData(houseData);
    }
    if (tdsDetails?.data && tdsDetails?.data?.deductionHeadsList) {
      const declarationData = getInvestmentDeclarationData(tdsDetails);
      setInvestmentDeclaration(declarationData);
    }
  }, [tdsDetails]);

  useEffect(() => {
    if (proofSubmissionStatus) {
      addToast({
        type: 'warning',
        title: 'WARNING',
        message: 'You have already Submitted your Investment Proofs',
      });
    }
  }, [proofSubmissionStatus]);

  if ((isEditMode && loadingModifyTdsDetailsError !== null) || loadingTdsDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingModifyTdsDetailsError?.message || loadingTdsDetailsError?.message || 'Error while loading TDS details.'}
      </Typography>
    );
  }

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  const indiaMetroCitiesOptions =
    isObject(tdsDetails?.data?.indiaMetroCitiesMap) && !isEmpty(tdsDetails?.data?.indiaMetroCitiesMap)
      ? Object.entries(tdsDetails?.data?.indiaMetroCitiesMap).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  const handleSubmit = async (event) => {
    setSelectedRegime(event?.regime || '');
    setFormData(event);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSubmitDialogClose = () => {
    setSubmitProofDialog(false);
  };

  const handleYesButton = async (formikData) => {
    const bodyFormData = new FormData();
    bodyFormData.append('taxRegime', get(formikData, 'regime', ''));
    bodyFormData.append('version', get(formikData, 'version', ''));
    bodyFormData.append('eid', tdsDetails?.data?.employeeDeductionDeclarationInstance?.employee?.id);
    bodyFormData.append('did', tdsDetails?.data?.employeeDeductionDeclarationInstance?.id);
    await configureUpdateFormData(bodyFormData, tdsDetails, formikData);
    bodyFormData.append(`housePropRecordCtr`, formikData?.property?.length);
    bodyFormData.append(`incomeFromOtherSources`, formikData?.incomeOtherSources || '');

    try {
      if (isEditMode) {
        const response = await formDataApi.put('/restPayIndex/modifyMyDeclarationAction', bodyFormData);
        if (response?.data?.status !== 'ERROR') {
          addToast({
            type: 'success',
            title: 'SUCCESS',
            message: response?.data?.message || 'Declaration updated successfully',
          });
          navigate('/home/mypay/tds-declaration');
        } else {
          addToast({
            type: 'error',
            title: 'ERROR',
            message: response?.data?.message || 'Unable to update declaration. Please contact your admin/HR',
          });
        }
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err?.message || `Failed to update Declaration`,
      });
    } finally {
      setOpenDialog(false);
      setFormData('');
    }
  };

  if ((isEditMode && isLoadingModifyTdsDetails) || isLoadingTdsDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  const handleCancel = () => {
    navigate('/home/mypay/tds-declaration');
  };

  const handleSubmitSuccessDialogClose = () => {
    setSubmitSuccessDialog(false);
    setSubmitProofDialog(false);
    navigate('/home/mypay/tds-declaration');
  };

  return (
    <Box>
      <TDSTemplate
        menuLists={menuLists}
        handleEdit={handleEdit}
        isEditMode={isEditMode}
        allowEdit={
          isEditMode && !(employeeDeductionDeclarationInstance?.proofSubmissionStatus === PROOF_SUBMISSION_STATUS)
        }
        employeeDeductionDeclarationInstance={employeeDeductionDeclarationInstance}
        basicDetails={{
          employeeName: tdsDetails?.data?.employeeDeductionDeclarationInstance?.employee?.fullName || '',
          employeeID: tdsDetails?.data?.employee?.profile?.employeeID || '',
          designation: tdsDetails?.data?.employee?.designation?.name || '',
          department: tdsDetails?.data?.employee?.department?.name || '',
          financialYear: tdsDetails?.data?.employeeDeductionDeclarationInstance?.financialYear || '',
          assessmentYear: tdsDetails?.data?.employeeDeductionDeclarationInstance?.assessmentYear || '',
          panNumber: tdsDetails?.data?.employee?.panNumberIndia || '-',
          aadhaarNumber: tdsDetails?.data?.employee?.aadhaarNumberIndia || '',
          dateOfJoining: tdsDetails?.data?.employee?.profile?.dateOfJoining,
          submissionsDeclaration:
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationStatus === 'Open for Declaration'
              ? 'Declaration Modifications (in case not yet approved) and submission are allowed at this time'
              : 'Declaration Modifications/Submissions are NOT allowed at this time.',
          submissionsStatus: tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationSubmissionStatus || '',
          submissionsActionOwner: isEditMode
            ? tdsDetails?.data?.actionOwner?.fullName || '-'
            : tdsDetails?.data?.actionOwnerFullName || '-',
          submissionsActionOwnerId: isEditMode
            ? tdsDetails?.data?.actionOwner?.profile?.employeeID || '-'
            : tdsDetails?.data?.actionOwnerEmployeeID || '-',
          submissionAllowed:
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationProofStatus ===
            'Open for Proof Submission'
              ? 'Investment/Rental Proof Modifications (in case not yet approved) and submission are allowed at this time'
              : 'Investment/Rental Proof Modifications/Submissions are NOT allowed at this time.',
          proofSubmissionStatus: tdsDetails?.data?.employeeDeductionDeclarationInstance?.proofSubmissionStatus || '',
          proofSubmissionActionOwner: isEditMode
            ? tdsDetails?.data?.actionOwner?.fullName || '-'
            : tdsDetails?.data?.actionOwnerFullName || '-',
          proofSubmissionActionOwnerId: isEditMode
            ? tdsDetails?.data?.actionOwner?.profile?.employeeID || '-'
            : tdsDetails?.data?.actionOwnerEmployeeID || '-',
          approvedAmountStatus:
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.approvedAmountUpdationStatus || '',
        }}
        houseRentDataRow={houseRentData?.length ? houseRentData : []}
        otherIncomeDetails={{
          otherSources: tdsDetails?.data?.taxWorkingObj?.incomeFromOtherSources,
        }}
        propertyList={
          tdsDetails?.data?.empHousePropertyDeclrList?.length
            ? tdsDetails?.data?.empHousePropertyDeclrList.map((employeeHousePropertyDeclarationInstance) => ({
                propertyType: employeeHousePropertyDeclarationInstance?.propertyType || '',
                propertyName: employeeHousePropertyDeclarationInstance?.propertyName || '',
                address: employeeHousePropertyDeclarationInstance?.addressStr || '',
                constructionCompletedOn: employeeHousePropertyDeclarationInstance?.constructionCompletionDate
                  ? dayjs(employeeHousePropertyDeclarationInstance?.constructionCompletionDate)
                  : null,
                loanAmount: employeeHousePropertyDeclarationInstance?.loanAmount || '',
                loanBorrowedOn: employeeHousePropertyDeclarationInstance?.loanBorrowedOn
                  ? dayjs(employeeHousePropertyDeclarationInstance?.loanBorrowedOn)
                  : null,
                lenderName: employeeHousePropertyDeclarationInstance?.lenderName || '',
                lenderPAN: employeeHousePropertyDeclarationInstance?.lenderPan || '',
                isJointlyOwnedProperty: employeeHousePropertyDeclarationInstance?.isJointlyOwnedProperty ? 'on' : '',
                coBorrower1Name: employeeHousePropertyDeclarationInstance?.coBorrower1Name || '',
                coBorrower1Pan: employeeHousePropertyDeclarationInstance?.coBorrower1Pan || '',
                coBorrower1Relationship: employeeHousePropertyDeclarationInstance?.coBorrower1Relationship || '',
                coBorrower2Name: employeeHousePropertyDeclarationInstance?.coBorrower2Name || '',
                coBorrower2Pan: employeeHousePropertyDeclarationInstance?.coBorrower2Pan || '',
                coBorrower2Relationship: employeeHousePropertyDeclarationInstance?.coBorrower2Relationship || '',
                coBorrower3Name: employeeHousePropertyDeclarationInstance?.coBorrower3Name || '',
                coBorrower3Pan: employeeHousePropertyDeclarationInstance?.coBorrower3Pan || '',
                coBorrower3Relationship: employeeHousePropertyDeclarationInstance?.coBorrower3Relationship || '',
                emiInterest1Year: employeeHousePropertyDeclarationInstance?.year1PerEMIInterest || '',
                emiInterest2Year: employeeHousePropertyDeclarationInstance?.year2PerEMIInterest || '',
                interestPercentage: employeeHousePropertyDeclarationInstance?.employeeLoanPercent || '',
                interestOnProperty:
                  employeeHousePropertyDeclarationInstance?.housingLoanInterestOnSelfOccupiedProperty || '',
                lettableValue: employeeHousePropertyDeclarationInstance?.lettableValue || '',
                taxPaidToLocalAuthorities: employeeHousePropertyDeclarationInstance?.taxPaidToLocalAuthorities || '',
                standardDeductionOnLettableValue:
                  employeeHousePropertyDeclarationInstance?.standardDeductionOnLettableValue || '',
                interestPaidOnHousingLoan:
                  employeeHousePropertyDeclarationInstance?.interestPaidOnHousingLoanForLetOutProperty || '',
                netIncomeFromHouse: employeeHousePropertyDeclarationInstance?.netIncomeFromHouseProperty || '',
              }))
            : []
        }
        downloadForm12bAttachment={handleAttachment}
        downloadAttachment={handleAttachment}
        form12bAttachment={tdsDetails?.data?.form12BB?.supportingDocument}
        investmentDeclarations={investmentDeclarations}
        auditLogData={tdsDetails?.data?.commentsMapList}
        cityOption={indiaMetroCitiesOptions}
        regimeType={tdsDetails?.data?.employeeDeductionDeclarationInstance?.taxRegime}
        regimeOptions={regimeOptions}
        propertyTypeOption={propertyTypeOption}
        updateButtonDisabled={
          !!(
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.lockStatus === 'Locked' ||
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationStatus === 'Closed for Declaration' ||
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationSubmissionStatus ===
              'Declaration Approved' ||
            tdsDetails?.data?.employeeDeductionDeclarationInstance?.declarationSubmissionStatus === 'Declaration Frozen'
          )
        }
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        userHasProofSubmitPermission={userHasProofSubmitPermission}
        isNewTaxRegime={isNewTaxRegime}
      />
      <Dialog
        open={openDialog}
        handleClose={handleDialogClose}
        dialogTitle={`Your current selection is ${selectedRegime}. Are you sure you want to continue?`}
        bodyContent="Please note: Tax regime can only be modified once in a financial year."
        secondaryBtn="Cancel"
        primaryBtn="Continue"
        handlePrimaryBtn={() => handleYesButton(formData)}
        handleSecondaryBtn={handleDialogClose}
      />
      <Dialog
        open={submitProofDialog}
        handleClose={handleSubmitDialogClose}
        dialogTitle="Investment Proof Submission"
        dialogDesc={
          <Typography color="neutral.600" align="center" textTransform="none">
            You have not uploaded any proofs yet. After submission, you will not be able to upload any proofs.
            <Typography color="neutral.600" align="center">
              Are you sure you want to continue?
            </Typography>
          </Typography>
        }
        secondaryBtn="Cancel"
        primaryBtn="Continue"
        handlePrimaryBtn={handleSubmission}
        handleSecondaryBtn={handleSubmitDialogClose}
      />
      <Dialog
        maxWidth="md"
        open={submitSuccessDialog}
        handleClose={handleSubmitSuccessDialogClose}
        dialogTitle="Investment Proofs submitted successfully"
        dialogDesc={
          <Typography
            color="neutral.600"
            sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', textTransform: 'none' }}
          >
            <b>Please Note:</b>
            <Typography color="neutral.600">
              <li> Upload of Form 12BB is mandatory for approving proof submissions. </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Please make sure you have uploaded your flexible benefit proofs (If applicable) before generating Form
                12BB.
              </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Click on ‘Go to Form 12BB’ button below to generate Form 12BB now or you can do it later by going to My
                Pay &nbsp; &nbsp; &gt; Form 12BB &gt; Generate Form 12BB.{' '}
              </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Download your Form 12BB, add sign and upload it back from My Pay &gt; Form 12BB &gt; Upload Form 12BB.
                Go to &nbsp; &nbsp; &nbsp;Form 12BB{' '}
              </li>
            </Typography>
          </Typography>
        }
        secondaryBtn="Cancel"
        primaryBtn="Go to Form12BB"
        handlePrimaryBtn={() => navigate('/home/mypay/form-12BB')}
        handleSecondaryBtn={handleSubmitSuccessDialogClose}
      />
      <Dialog
        open={openDialog}
        handleClose={handleDialogClose}
        dialogTitle={`Your current selection is ${selectedRegime}. Are you sure you want to continue?`}
        bodyContent="Please note: Tax regime can only be modified once in a financial year."
        secondaryBtn="Cancel"
        primaryBtn="Continue"
        handlePrimaryBtn={() => handleYesButton(formData)}
        handleSecondaryBtn={handleDialogClose}
      />
    </Box>
  );
};

export default ViewTDSPage;
